import { apiRoute, authHeader, handleResponse } from '../helpers'

export const contentService = {
  getContent
}

function getContent (slug) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/content/page/' + slug, requestOptions).then(handleResponse)
}
