import Vue from 'vue'

const state = {
  transaction: {
    id: null,
    link: null,
    type: 'goods',
    amounts: null,
    modified: false,
    general: {}
  },
  iteration: 0
}

const actions = {
  update ({ commit }, params) {
    commit('update', params)
  },
  updateState ({ commit }, params) {
    commit('updateState', params)
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  update (state, params) {
    state.transaction = params.transaction
    state.iteration++
  },
  updateState (state, params) {
    Vue.set(state.transaction, params.state, params.value)
    state.iteration++
  },
  clear (state) {
    state.transaction = {
      id: null,
      link: null,
      amounts: null,
      modified: false,
      general: {}
    }
  }
}

export const transactionChangeQty = {
  namespaced: true,
  state,
  actions,
  mutations
}
