import Vue from 'vue'

const state = {
  link: null,
  haveAccount: false,
  paymentMethod: null,
  register: {
    name: null,
    surname: null,
    registration_type: 'Individual',
    mobile: null,
    email: null,
    company: null,
    invited_by: null
  },
  ozow: {
    orderid: null,
    url: null,
    acquiring: true,
    processing: false,
    processed: false,
    error: null
  }
}

const actions = {
  updateState ({ commit }, params) {
    commit('updateState', params)
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  updateState (state, params) {
    Vue.set(state, params.state, params.value)
  },
  clear (state) {
    state.type = null
    state.role = null
    state.transaction = {}
  }
}

export const paymentLink = {
  namespaced: true,
  state,
  actions,
  mutations
}
