import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import IdleVue from 'idle-vue'
import LoadingButton from './components/global/LoadingButton'
import VueGtag from 'vue-gtag'
import VueFeather from 'vue-feather'
import VueFormGenerator from 'vue-form-generator'
import Vuelidate from 'vuelidate'
import VueMatchHeights from 'vue-match-heights'
import VueQRCodeComponent from 'vue-qrcode-component'
import VueSlider from 'vue-slider-component'
import VueTheMask from 'vue-the-mask'

import App from './App.vue'
import { router } from './helpers/router'
import { store } from './store'

import CardInputNew from './components/form-generator/CardInput-New.vue'
import TextInput from './components/form-generator/TextInput.vue'
import { MazCheckbox, MazInput, MazPhoneNumberInput, MazSelect, MazSlider, MazProgressBar } from 'maz-ui'

import 'vue-form-generator/dist/vfg-core.css'
import './assets/css/custom.scss'
import './assets/css/extras.scss'
import './assets/css/extras.2.scss'
import './assets/css/flags.scss'
import './assets/css/agGridStyleOverride.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCar, faInfoCircle, faPenAlt, faStopwatch, faWallet, faBatteryEmpty, faLink, faMoneyCheckAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faBuilding, faCreditCard } from '@fortawesome/free-regular-svg-icons'
import { faPaypal } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faBuilding, faCar, faInfoCircle, faCreditCard, faPaypal, faPenAlt, faStopwatch, faWallet, faBatteryEmpty, faLink, faMoneyCheckAlt, faPlusCircle)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(MazCheckbox)
Vue.use(MazInput)
Vue.use(MazPhoneNumberInput)
Vue.use(MazSelect)
Vue.use(MazSlider)
Vue.use(MazProgressBar)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('LoadingButton', LoadingButton)
Vue.use(VueGtag, {
  config: { id: 'G-2LQWP02WXL' },
  pageTrackerTemplate (to) {
    return {
      page_title: to.name,
      page_path: to.path
    }
  }
}, router)
Vue.use(VueFeather)
Vue.component('VueSlider', VueSlider)
Vue.use(VueFormGenerator, {
  validators: {
    telNumber: (value) => {
      if (!value) return []
      const regex = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/

      if (!regex.test(value)) {
        return ['Invalid phone number.']
      } else {
        return []
      }
    }
  }
})

Vue.component('field-card-input-new', CardInputNew)
Vue.component('field-text-input', TextInput)

Vue.use(Vuelidate)
Vue.use(VueMatchHeights)
Vue.use(require('vue-moment'))
Vue.component('qrcode-vue', VueQRCodeComponent)
Vue.use(VueTheMask)

const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 300000,
  startAtIdle: false
})

Vue.config.productionTip = false

const formatCurrency = function (value, currency, numDigits) {
  if (typeof value !== 'number') return value
  const _numDigits = numDigits === null || numDigits === undefined ? 2 : numDigits
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'ZAR',
    minimumFractionDigits: _numDigits,
    maximumFractionDigits: _numDigits
  })

  const _rounded = Math.round(value * 1000) / 1000
  const _value = formatter.format(_rounded).replace('ZAR', 'R')
  return _value
}

Vue.filter('formatUser', function (value) {
  if (!value) return 'Undisclosed'
  if (value.personal && value.personal.first_names) {
    return value.personal.first_names + ' ' + value.personal.surname
  } else {
    return value.username
  }
})

Vue.filter('formatUserShort', function (value) {
  if (!value) return 'Undisclosed'
  if (value.personal && value.personal.first_names) {
    const _names = value.personal.first_names.split(' ')
    let _return = ''
    _names.forEach(_name => {
      _return += _name.charAt(0)
    })
    return _return + ' ' + value.personal.surname
  } else {
    return value.username
  }
})

Vue.filter('formatCompany', function (value) {
  if (!value) return 'Undisclosed'
  if (value.name) {
    if (value.name === value.trading_name) {
      return value.name
    } else {
      return value.name + (value.trading_name ? ' t/a ' + value.trading_name : '')
    }
  } else {
    return 'Undisclosed'
  }
})

Vue.filter('formatAmount', function (value, currency, numDigits) {
  if (!value && value !== 0) return ''
  return formatCurrency(value, currency, numDigits)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
