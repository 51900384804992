import { feesService } from '../services'

const state = {
  fees: null,
  status: null
}

const actions = {
  get ({ dispatch, commit, rootState }) {
    commit('loading')

    feesService.getAllFees(rootState.currency.name)
      .then(
        fees => {
          if (fees) {
            commit('loaded', fees)
          } else {
            commit('notFound')
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.fees = null
    state.status = 'loading'
  },
  loaded (state, fees) {
    state.fees = fees
    state.status = 'loaded'
  },
  notFound (state) {
    state.fees = null
    state.status = 'not-found'
  },
  failed (state) {
    state.fees = null
    state.status = 'failed'
  },
  clear (state) {
    state.fees = null
    state.status = null
  }
}

export const feesAll = {
  namespaced: true,
  state,
  actions,
  mutations
}
