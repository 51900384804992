import { removeUser } from './removeUser'

export function handleResponse (response) {
  return response.text().then(text => {
    const data = text && text !== 'Unauthorized' && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        removeUser()
        const error = (data && data.status) || response.statusText
        return Promise.reject(error)
      } else if (response.status === 429) {
        removeUser()
        const error = 'There were too many invalid requests and we have suspended access temporarily to ensure security of your account against unauthorised access. Please, try again in a few minutes.'
        return Promise.reject(error)
      } else {
        let error = data.message || (data || response.statusText)

        if (data.error) {
          if (Array.isArray(data.error) && data.error.length > 0) {
            error = data.error[0]
          } else {
            error = data.error
          }
        }

        return Promise.reject(error)
      }
    }

    if (response.status === 204) {
      return null
    }

    return data
  })
}
