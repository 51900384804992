import Vue from 'vue'

const state = {
  type: null,
  role: null,
  transaction: {
    actors: {
      buyer: null,
      seller: null,
      agents: []
    },
    amounts: null,
    general: {},
    project: {},
    vehicle: {},
    rental: {}
  }
}

const actions = {
  update ({ commit }, params) {
    commit('update', params)
  },
  updateState ({ commit }, params) {
    commit('updateState', params)
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  update (state, params) {
    state.type = params.type
    state.role = params.role
    state.transaction = params.transaction
  },
  updateState (state, params) {
    Vue.set(state.transaction, params.state, params.value)
  },
  clear (state) {
    state.type = null
    state.role = null
    state.transaction = {
      actors: {
        buyer: null,
        seller: null,
        agents: []
      },
      amounts: null,
      general: {},
      project: {},
      vehicle: {},
      rental: {}
    }
  }
}

export const transactionNew = {
  namespaced: true,
  state,
  actions,
  mutations
}
