<template>
  <b-modal id="change_qty" size="xl" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc :hide-backdrop="isEmbedded" :modal-class="isEmbedded ? 'embedded' : null">
    <h4 class="font-weight-400 color-grey mb-1">Change item quantities</h4>
    <p v-if="!transaction.modified">Seller allows item quantities to be altered. Please, select line items you wish to purchase and specify their quantity. Once done, tap "Complete" to return to the payment method selection.</p>
    <p class="mt-3 d-none d-md-block">
      Items
      <b-row class="mt-2 mb-2">
        <b-col cols="6" class="pr-1">
          Description
        </b-col>
        <b-col cols="1" class="text-right px-1">
          Available
        </b-col>
        <b-col cols="1" class="text-right px-1">
          Selected
        </b-col>
        <b-col cols="2" class="text-right px-1">
          Price
        </b-col>
        <b-col cols="2" class="text-right pl-1">
          Total
        </b-col>
      </b-row>
      <b-row class="mt-1" v-for="(record, $index) in items" :key="$index">
        <b-col cols="6" class="pr-1">
          <strong><feather class="mr-1" type="check-circle" v-if="record.selected === true" size="14px" stroke="#5484ff" />{{ record.name }}</strong>
        </b-col>
        <b-col cols="1" class="text-right px-1" v-if="selectedItem !== $index || !(selectedItem === $index && noSlider)">
          {{ parseFloat(record.available).toLocaleString(undefined, { minimumFractionDigits: 0 }) }}
        </b-col>
        <b-col :cols="selectedItem === $index && noSlider ? 2 : 1" class="text-right px-1">
          <span v-if="selectedItem !== $index || !(selectedItem === $index && noSlider)">{{ parseFloat(record.quantity).toLocaleString(undefined, { minimumFractionDigits: 0 }) }}</span>
          <b-form-group class="my-0" v-show="selectedItem === $index && noSlider">
            <maz-input v-model="record.quantity" type="number" :debounce="true" :max="parseFloat(record.available)" :placeholder="'Available: ' + parseFloat(record.available).toLocaleString(undefined, { minimumFractionDigits: 0 })" :size="'sm'">
              <template slot="icon-right">
                <a href="#" @click.prevent="zeroItem($index)"><feather type="x" size="14px" stroke="orangered"></feather></a>
                <a class="ml-1" href="#" @click.prevent="selectItem($index)"><feather type="check" size="14px" stroke="#00c45b"></feather></a>
              </template>
            </maz-input>
          </b-form-group>
          <a v-if="selectedItem !== $index || !(selectedItem === $index && noSlider)" class="ml-1" href="#" @click.prevent="selectItem($index)"><feather type="edit" size="14px" /></a>
        </b-col>
        <b-col cols="2" class="text-right px-1">
          {{ formatCurrency(record.price, transaction.amounts.currency, 2) }}
        </b-col>
        <b-col cols="2" class="text-right pl-1">
          <strong>{{ formatCurrency(record.quantity * record.price, transaction.amounts.currency, 2) }}</strong>
        </b-col>
        <b-col cols="12" v-if="selectedItem === $index && !noSlider">
          <b-form-group class="mt-3 mb-1 px-3">
            <vue-slider class="d-none d-md-block" :id="'slider_' + $index" v-model="record.quantity" type="range" :lazy="true" :min="0" :max="record.available" :step="1" :dot-size="26" :height="8"></vue-slider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="transaction && transaction.amounts && transaction.amounts.currency">
        <b-col cols="7" class="pr-1">
          <strong>Total</strong>
        </b-col>
        <b-col cols="1" class="text-right px-1">
          <strong class="mr-3 pr-1">{{ parseFloat(totalSelected).toLocaleString(undefined, { minimumFractionDigits: 0 }) }}</strong>
        </b-col>
        <b-col cols="4" class="text-right pl-1">
          <strong>{{ formatCurrency(totalAmount, transaction.amounts.currency, 2) }}</strong>
        </b-col>
      </b-row>
      <b-row class="mt-1 mb-2" v-if="feesDisplay">
        <b-col cols="8" class="pr-1 line-height-125">
          <strong>My portion of the fees</strong><br />
          <small>Dependent on the payment method chosen</small>
        </b-col>
        <b-col cols="4" class="text-right pl-1">
          <strong>{{ formatCurrency(feesDisplay[0], transaction.amounts.currency, 2) }} - {{ formatCurrency(feesDisplay[1], transaction.amounts.currency, 2) }}</strong>
        </b-col>
      </b-row>
    </p>
    <p class="mt-3 d-block d-md-none">
      Items
      <b-row class="mt-2 mb-2">
        <b-col cols="6" class="pr-1">
          Description
        </b-col>
        <b-col cols="2" class="text-right px-1">
          Quantity
        </b-col>
        <b-col cols="4" class="text-right pl-1">
          Total
        </b-col>
      </b-row>
      <b-row class="mt-1" v-for="(record, $index) in items" :key="$index">
        <b-col :cols="selectedItem === $index && noSlider ? 4 : 6" class="pr-1">
          <strong><feather class="mr-1" type="check-circle" v-if="record.selected === true" size="14px" stroke="#5484ff" />{{ record.name }}</strong>
        </b-col>
        <b-col :cols="selectedItem === $index && noSlider ? 4 : 2" class="text-right px-1">
          <span v-if="selectedItem !== $index || !(selectedItem === $index && noSlider)">{{ parseFloat(record.quantity).toLocaleString(undefined, { minimumFractionDigits: 0 }) }}</span>
          <b-form-group class="my-0" v-show="selectedItem === $index && noSlider">
            <maz-input v-model="record.quantity" type="number" :debounce="true" :placeholder="'Available: ' + parseFloat(record.available).toLocaleString(undefined, { minimumFractionDigits: 0 })" :size="'sm'">
              <template slot="icon-right">
                <a href="#" @click.prevent="zeroItem($index)"><feather type="x" size="14px" stroke="orangered"></feather></a>
                <a class="ml-1" href="#" @click.prevent="selectItem($index)"><feather type="check" size="14px" stroke="#00c45b"></feather></a>
              </template>
            </maz-input>
          </b-form-group>
          <a v-if="selectedItem !== $index || !(selectedItem === $index && noSlider)" class="ml-1" href="#" @click.prevent="selectItem($index)"><feather type="edit" size="14px" /></a>
        </b-col>
        <b-col cols="4" class="text-right pl-1">
          <strong>{{ formatCurrency(record.quantity * record.price, transaction.amounts.currency, 2) }}</strong>
        </b-col>
        <b-col cols="6" class="text-muted">
          <small>Available: </small>{{ parseFloat(record.available).toLocaleString(undefined, { minimumFractionDigits: 0 }) }}
        </b-col>
        <b-col cols="6" class="text-right text-muted">
          <small>Price: </small>{{ formatCurrency(record.price, transaction.amounts.currency, 2) }}
        </b-col>
        <b-col cols="12" v-if="selectedItem === $index && !noSlider">
          <b-form-group class="mt-3 mb-1 px-3">
            <vue-slider :id="'slider_' + $index" v-model="record.quantity" type="range" :lazy="true" :min="0" :max="record.available" :step="1" :dot-size="26" :height="8"></vue-slider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="transaction && transaction.amounts && transaction.amounts.currency">
        <b-col cols="7" class="pr-1">
          <strong>Total</strong>
        </b-col>
        <b-col cols="1" class="text-right px-1">
          <strong class="mr-3 pr-1">{{ parseFloat(totalSelected).toLocaleString(undefined, { minimumFractionDigits: 0 }) }}</strong>
        </b-col>
        <b-col cols="4" class="text-right pl-1">
          <strong>{{ formatCurrency(totalAmount, transaction.amounts.currency, 2) }}</strong>
        </b-col>
      </b-row>
      <b-row class="mt-1 mb-2" v-if="feesDisplay">
        <b-col cols="8" class="pr-1 line-height-125">
          <strong>My portion of the fees</strong><br />
          <small>Dependent on the payment method chosen</small>
        </b-col>
        <b-col cols="4" class="text-right pl-1">
          <strong>{{ formatCurrency(feesDisplay[0], transaction.amounts.currency, 2) }} - {{ formatCurrency(feesDisplay[1], transaction.amounts.currency, 2) }}</strong>
        </b-col>
      </b-row>
    </p>
    <b-row class="mt-2 mb-0">
      <b-col md="6" offset-md="6">
        <b-form-group class="mb-0 ml-3">
          <maz-checkbox v-model="noSlider">I don't want the slider for quantity selection.</maz-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-0">
      <b-col cols="12" md="6">
        <b-button variant="outline-custom" pill block class="mt-3" @click.prevent="$bvModal.hide('change_qty')">
          Close
        </b-button>
      </b-col>
      <b-col cols="12" md="6">
        <b-button variant="custom" pill block class="mt-3" @click.prevent="save()" :disabled="!totalAmount || totalAmount <= 0">
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { feesService } from '@/services'

import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      items: [],
      watchers: [],
      selectedItem: null,
      fees: [],
      noSlider: false
    }
  },
  computed: {
    ...mapState({
      iteration: state => state.transactionChangeQty.iteration,
      transaction: state => state.transactionChangeQty.transaction
    }),
    totalSelected () {
      return this.items.reduce((partialSum, _item) => partialSum + _item.quantity, 0)
    },
    totalAmount () {
      return this.items.reduce((partialSum, _item) => partialSum + (_item.quantity * _item.price), 0)
    },
    feesDisplay () {
      if (this.transaction && this.transaction.amounts && this.totalAmount > 0) {
        const _amounts = this.transaction.amounts
        const _split = _amounts.fee_payable_by.buyer_percentage / 100
        const _minimum = Math.min.apply(Math, this.fees.map(function(o) { return o.fee })) * _split * 1.15
        const _maximum = Math.max.apply(Math, this.fees.map(function(o) { return o.fee })) * _split * 1.15
        return [_minimum, _maximum]
      } else {
        return false
      }
    },
    isEmbedded () {
      if (this.$route.path.indexOf('embedded') >= 0) {
        return true
      } else {
        return false
      }
    }
  },
  created () {
    const _enabled = localStorage.getItem('showQtySliders')
    if (_enabled === 'disabled') {
      this.noSlider = true
    }
  },
  mounted () {
    if (this.transaction && this.transaction.general && this.transaction.general.items && this.transaction.general.items.length > 0) {
      this.watchers.forEach(_watcher => {
        _watcher.unwatch()
      })
      this.watchers = []
      this.items = []
      this.transaction.general.items.forEach(_item => {
        this.items.push(_item)
        const unwatch = this.$watch(() => _item, this.handleChange, {deep: true})
        this.watchers.push({ id: _item._id, unwatch: unwatch })
      })
    }
  },
  methods: {
    ...mapActions('transactionChangeQty', {
      clearQties: 'clear',
      updateQties: 'update',
      updateQtyState: 'updateState'
    }),
    save: function () {
      if (this.totalSelected > 0) {
        this.items.forEach(_item => {
          if (_item.quantity > _item.available) {
            _item.quantity = _item.available
          }
        })
        this.transaction.amounts.amount = this.totalAmount
        this.transaction.amounts.fees.forEach(_fee => {
          const _item = this.fees.find(__fee => __fee.method.toLowerCase() === _fee.method.name.toLowerCase())
          _fee.fee = _item.fee
        })
        this.updateQtyState({ state: 'amounts', value: this.transaction.amounts })
        this.updateQtyState({ state: 'modified', value: true })
        this.$bvModal.hide('change_qty')
      }
    },
    formatCurrency: function (value, currency, numDigits) {
      if (typeof value !== 'number') return value
      const _numDigits = numDigits === null || numDigits === undefined ? 2 : numDigits
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'ZAR',
        minimumFractionDigits: _numDigits,
        maximumFractionDigits: _numDigits
      })

      const _rounded = Math.round(value * 1000) / 1000
      const _value = formatter.format(_rounded).replace('ZAR', 'R')
      return _value
    },
    selectItem: function (index) {
      if (this.selectedItem !== index) {
        this.selectedItem = index
      } else {
        const _item = this.items[index]
        if (_item.quantity > _item.available) {
          _item.quantity = _item.available
        }
        this.selectedItem = null
      }
    },
    zeroItem: function (index) {
      const _item = this.items[index]
      _item.selected = false
      _item.quantity = 0
      this.selectedItem = null
    },
    handleChange: function (value) {
      const _item = this.items.find(_item => _item._id === value._id)
      if (value.quantity === 0) {
        _item.selected = false
      } else if (_item.quantity > _item.available) {
        _item.quantity = _item.available
        _item.selected = true
      } else {
        _item.selected = true
      }
    }
  },
  watch: {
    noSlider (value) {
      localStorage.setItem('showQtySliders', (value ? 'disabled' : 'enabled'))
    },
    iteration () {
      if (this.transaction && this.transaction.general && this.transaction.general.items && this.transaction.general.items.length > 0) {
        this.watchers.forEach(_watcher => {
          _watcher.unwatch()
        })
        this.watchers = []
        this.items = []
        this.transaction.general.items.forEach(_item => {
          this.items.push(_item)
          const unwatch = this.$watch(() => _item, this.handleChange, {deep: true})
          this.watchers.push({ id: _item._id, unwatch: unwatch })
        })
      }
    },
    totalAmount (value) {
      feesService.getFees(value, this.transaction.amounts.currency)
        .then(
          fees => {
            if (fees && fees.length > 0) {
              this.fees = fees
            }
          },
          error => {
            console.log(error)
          }
        )
    }
  }
}
</script>
