const state = {
  show: false
}

const actions = {
  show ({ commit }) {
    commit('show')
  },
  hide ({ commit }) {
    commit('hide')
  }
}

const mutations = {
  show (state) {
    state.show = true
  },
  hide (state) {
    state.show = false
  }
}

export const social = {
  namespaced: true,
  state,
  actions,
  mutations
}
