<template>
  <div class="auth-layout">
    <b-row class="no-gutters">
      <b-col md="5" class="position-fixed">
        <div class="left-side bg-gradient d-none d-md-block">
          <div class="bg-img h-100 p-4 pr-md-0 pl-md-5 py-md-5">
            <img :src="require('@/assets/images/logo.svg')" class="logo" />
            <div class="d-table w-100 h-100 ml-5 pl-4">
              <div class="d-table-cell align-middle main no-overflow">
                <div class="d-none d-md-block position-static list-group py-2 pl-4 pr-0 mt-4 ml-5 mr--4" v-if="link && link._id">
                  <h4 class="mx-4 mb-1 border-bottom-white color-white mobile-size-reduce-sm" v-if="link.transaction && link.transaction._id">
                    Details
                  </h4>
                  <div class="rounded box-shadow bg-white px-4 pt-4 pb-2 mobile-size-reduce mr-5" v-if="link.transaction && link.transaction._id">
                    <h3 class="mobile-size-reduce mb-0">
                      <v-clamp :max-lines="1" autoresize :ellipsis="''">
                        <template #after="{ toggle, expanded, clamped}">
                          <span v-if="clamped">&#8230; <a href="#" @click.prevent="toggle"><feather type="arrow-right"></feather></a></span>
                          <span v-if="expanded"><a href="#" @click.prevent="toggle"><feather type="arrow-left"></feather></a></span>
                        </template>
                        {{ link.transaction.name }}
                      </v-clamp>
                    </h3>
                    <p class="text-uppercase">Reference: {{ link.transaction.reference }}</p>
                    <div v-if="!link.transaction.payment || !link.transaction.payment.changeqty">
                      <p>
                        Transaction total<br />
                        <strong class="font-size-24 mobile-size-reduce">{{ formatCurrency(link.transaction.amounts.amount, link.transaction.amounts.currency, 2) }}</strong>
                      </p>
                      <p v-if="fees">
                        My portion of the fees *<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ formatCurrency(fees[0], link.transaction.amounts.currency, 2) }} - {{ formatCurrency(fees[1], link.transaction.amounts.currency, 2) }}</strong>
                      </p>
                      <p v-if="commission">
                        My portion of the commission *<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ formatCurrency(commission, link.transaction.amounts.currency, 2) }}</strong>
                      </p>
                      <p v-if="total">
                        Total payable *<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ formatCurrency(total[0], link.transaction.amounts.currency, 2) }} - {{ formatCurrency(total[1], link.transaction.amounts.currency, 2) }}</strong>
                      </p>
                      <p>
                        Date created<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ link.created_at | moment("D MMMM YYYY, LT") }}</strong>
                      </p>
                      <p v-if="loggedIn && link.created_by">
                        Created by{{ link.transaction && link.transaction.actors && link.transaction.actors.seller && link.created_by._id === link.transaction.actors.seller._id ? ' / Seller' : null }}<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ link.created_by | formatUserShort }}</strong>
                      </p>
                    </div>
                    <div v-else-if="inPayment">
                      <p>
                        <small class="float-right mt-1"><a href="#" class="text-uppercase" @click.prevent="$bvModal.show('change_qty')">Change quantities <feather type="arrow-right" size="0.75rem"></feather></a></small>
                        Items
                        <b-row v-for="(record, $index) in link.transaction.general.items" :key="$index">
                          <b-col cols="5">
                            {{ record.quantity }} x <strong>{{ record.name }}</strong>
                          </b-col>
                          <b-col cols="3" class="d-none d-md-block text-right">
                            {{ formatCurrency(record.price, link.transaction.amounts.currency, 2) }}
                          </b-col>
                          <b-col cols="4" class="text-right">
                            <strong>{{ formatCurrency(record.quantity * record.price, link.transaction.amounts.currency, 2) }}</strong>
                          </b-col>
                        </b-row>
                        <small v-if="_transaction && !_transaction.transaction.modified">Note: You haven't changed any of the items or item quantities yet.</small>
                      </p>
                      <p>
                        Transaction total<br />
                        <strong class="font-size-24 mobile-size-reduce">{{ formatCurrency(link.transaction.amounts.amount, link.transaction.amounts.currency, 2) }}</strong>
                      </p>
                      <p v-if="fees">
                        My portion of the fees *<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ formatCurrency(fees[0], link.transaction.amounts.currency, 2) }} - {{ formatCurrency(fees[1], link.transaction.amounts.currency, 2) }}</strong>
                      </p>
                    </div>
                    <div v-else>
                      <p>
                        Transaction total<br />
                        <strong class="font-size-18 mobile-size-reduce">Seller allows item quantities to be altered.</strong><br />
                        You need to login in order to view transaction details and select the items you wish to purchase.
                      </p>
                      <p>
                        Date created<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ link.created_at | moment("D MMMM YYYY, LT") }}</strong>
                      </p>
                      <p v-if="loggedIn && link.created_by">
                        Created by{{ link.transaction && link.transaction.actors && link.transaction.actors.seller && link.created_by._id === link.transaction.actors.seller._id ? ' / Seller' : null }}<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ link.created_by | formatUserShort }}</strong>
                      </p>
                    </div>
                    <p class="d-none d-md-block" v-if="loggedIn && link.transaction && link.transaction.actors && link.transaction.actors.seller && link.transaction.actors.seller._id !== link.created_by._id">
                      Seller<br />
                      <strong class="font-size-20 mobile-size-reduce-sm">{{ link.transaction.actors.seller | formatUser }}</strong>
                    </p>
                    <p class="d-none d-md-block" v-if="loggedIn && link.transaction && link.transaction.actors && link.transaction.actors.seller_company">
                      Seller company<br />
                      <strong class="font-size-20 mobile-size-reduce-sm">{{ link.transaction.actors.seller_company | formatCompany }}</strong>
                    </p>
                    <p v-if="fees">
                      <small>* Dependent on the payment method chosen</small>
                    </p>
                  </div>
                </div>
                <h1 class="color-white mobile-size-reduce mb-0" v-else>
                  Creating trust between strangers
                  <br />
                  <small>when buying and selling</small>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="7" offset-md="5">
        <router-view/>
      </b-col>
    </b-row>
    <b-modal id="not_available" size="md" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <div>
        <h4 class="font-weight-400 mt-2 mb-0 text-center">Error</h4>
        <b-row>
          <b-col class="text-center">
            <feather type="alert-circle" size="10rem" stroke="#dc3545" class="my-3"></feather>
            <p v-if="link && link.transaction && link.transaction.payment_status !== 'Pending'"><b>This transaction is no longer available for funding.</b></p>
            <p v-if="link && link.wallet_fund && link.wallet_fund.payment_status !== 'Pending'"><b>This wallet funding is no longer available for funding.</b></p>
          </b-col>
        </b-row>
        <p>You can follow one of these available actions:</p>
        <ul>
          <li>Verify that the correct payment link was used and try again.</li>
          <li v-if="link && link.transaction && link.transaction._id">Contact the seller to verify if the required goods or services are still available and request another payment link.</li>
          <li v-if="link && link.wallet_fund && link.wallet_fund._id">Create another wallet funding payment link via <a href="https://app.truzo.com">Truzo website</a> or Truzo mobile app and try again.</li>
          <li>Contact Truzo support via <a href="https://truzo.com/support/" target="_blank">our ticketing system</a>.</li>
        </ul>
        <b-row class="mb-2">
          <b-col cols="12" md="6" offset-md="6">
            <b-button variant="outline-custom" pill block class="mt-3" @click.prevent="closeError()">
              Close
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { paymentService } from '@/services'
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import { mapActions, mapState } from 'vuex'

import VClamp from 'vue-clamp'

export default {
  components: {
    VClamp
  },
  data () {
    return {
      link: null
    }
  },
  mounted () {
    Vue.use(VueReCaptcha, { siteKey: '6LcmN7wUAAAAAG_iv69Y7_TYooxS-2kptKNk8rDK' })

    if (this.linkid) {
      let __link = null
      if (this.loggedIn) {
        __link = paymentService.getLink(this.linkid)
      } else {
        __link = paymentService.getEmbeddedLink(this.linkid)
      }

      __link
        .then(
          link => {
            if (link) {
              this.link = link

              if (link.transaction && link.transaction._id) {
                if (link.transaction.payment_status !== 'Pending') {
                  this.$bvModal.show('not_available')
                }

                if (link.transaction && link.transaction.payment && link.transaction.payment.changeqty) {
                  const _transaction = this._transaction.transaction
                  if (this._transaction) {
                    if (_transaction && _transaction.id && _transaction.link === this.linkid) {
                      link.transaction.amounts = _transaction.amounts
                      link.transaction.general = _transaction.general
                    }
                  }
                }
              }
            } else {
              this.link = {
                link: 'Payment link is not available. Please, try to generate a new one.'
              }
            }
          },
          error => {
            console.log(error)
          }
        )
    }
  },
  computed: {
    ...mapState({
      payment: state => state.link,
      status: state => state.auth.status,
      _transaction: state => state.transactionChangeQty
    }),
    loggedIn () {
      return this.status.loggedIn
    },
    inPayment () {
      if (this.loggedIn && this.$route.path.indexOf('/pay') >= 0) {
        return true
      } else {
        return false
      }
    },
    linkid () {
      if (this.payment && this.payment.type === 'payment' && this.payment.id) {
        return this.payment.id
      } else {
        return false
      }
    },
    fees () {
      if (this.link && this.link._id) {
        if (this.link.transaction && this.link.transaction._id) {
          const _amounts = this.link.transaction.amounts
          const _split = _amounts.fee_payable_by.buyer_percentage / 100
          const _minimum = Math.min.apply(Math, _amounts.fees.map(function(o) { return o.fee })) * _split * 1.15
          const _maximum = Math.max.apply(Math, _amounts.fees.map(function(o) { return o.fee })) * _split * 1.15
          if (_minimum > 0 || _maximum < 0) {
            return [_minimum, _maximum]
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    commission () {
      if (this.link && this.link._id) {
        if (this.link.transaction && this.link.transaction._id) {
          const _amounts = this.link.transaction.amounts
          const _split = _amounts.split.find(_record => _record.actor === 'Buyer')
          if (_split && _split.agent_comm && _split.agent_comm.length > 0) {
            return _split.agent_comm[0].fee
          } else {
            return 0
          }
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    total () {
      if (this.link && this.link._id) {
        if (this.link.transaction && this.link.transaction._id) {
          const _amounts = this.link.transaction.amounts
          const _total = this.commission + _amounts.amount
          return [_total + this.fees[0], _total + this.fees[1]]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    iteration () {
      return this._transaction.iteration
    }
  },
  methods: {
    ...mapActions('link', ['clear']),
    formatCurrency: function (value, currency, numDigits) {
      if (typeof value !== 'number') return value
      const _numDigits = numDigits === null || numDigits === undefined ? 2 : numDigits
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'ZAR',
        minimumFractionDigits: _numDigits,
        maximumFractionDigits: _numDigits
      })

      const _rounded = Math.round(value * 1000) / 1000
      const _value = formatter.format(_rounded).replace('ZAR', 'R')
      return _value
    },
    closeError: function () {
      this.link = null
      this.clear()
      this.$bvModal.hide('not_available')
    }
  },
  watch: {
    linkid (value) {
      if (!value) {
        this.link = null
      } else {
        if (this.linkid) {
          paymentService.getLink(this.linkid)
            .then(
              link => {
                if (link) {
                  this.link = link

                  if (link.transaction && link.transaction._id) {
                    if (link.transaction.payment_status !== 'Pending') {
                      this.$bvModal.show('not_available')
                    }
                  }
                } else {
                  this.link = {
                    link: 'Payment link is not available. Please, try to generate a new one.'
                  }
                }
              },
              error => {
                console.log(error)
              }
            )
        }
      }
    },
    iteration () {
      const _transaction = this._transaction.transaction
      if (_transaction && _transaction.id && _transaction.link === this.linkid) {
        if (this.link.transaction && this.link.transaction._id) {
          this.link.transaction.amounts = _transaction.amounts
          this.link.transaction.general = _transaction.general
        }
      }
    },
    loggedIn (value) {
      if (value) {
        if (this.linkid) {
          paymentService.getLink(this.linkid)
            .then(
              link => {
                if (link) {
                  this.link = link

                  if (link.transaction && link.transaction._id) {
                    if (link.transaction.payment_status !== 'Pending') {
                      this.$bvModal.show('not_available')
                    }
                  }
                } else {
                  this.link = {
                    link: 'Payment link is not available. Please, try to generate a new one.'
                  }
                }
              },
              error => {
                console.log(error)
              }
            )
        }
      }
    }
  }
}
</script>
