import { apiRoute, authHeader, handleResponse } from '../helpers'

export const transactionService = {
  get,
  getActions,
  getAll,
  getLinked,
  getMinimum,
  getNotes,
  accept,
  addNote,
  approve,
  cancel,
  release,
  releaseMilestone,
  deliver,
  deliverMilestone,
  dispute,
  negotiate,
  quickCreate,
  create,
  createFromRecurring,
  edit,
  getEdit,
  startEdit,
  stopEdit,
  payEft,
  payWallet,
  payNewCard,
  payCard,
  poll3dSecure,
  pollStitch,
  payStitch,
  getOzowUrl,
  payOzow,
  pollOzow,
  share
}

function getActions () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/activities', requestOptions).then(handleResponse)
}

function get (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/' + id, requestOptions).then(handleResponse)
}

function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/list', requestOptions).then(handleResponse)
}

function getLinked () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/linked', requestOptions).then(handleResponse)
}

function getMinimum (currency) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/minimum/' + (currency || 'ZAR'), requestOptions).then(handleResponse)
}

function getNotes (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/notes/' + id, requestOptions).then(handleResponse)
}

function accept (id, company) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(company)
  }

  return fetch(apiRoute() + '/api/v3/transaction/accept/' + id, requestOptions).then(handleResponse)
}

function addNote (id, note) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(note)
  }

  return fetch(apiRoute() + '/api/v3/transaction/notes/' + id, requestOptions).then(handleResponse)
}

function approve (id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/approve/' + id, requestOptions).then(handleResponse)
}

function cancel (id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/cancel/' + id, requestOptions).then(handleResponse)
}

function release (id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/release/' + id, requestOptions).then(handleResponse)
}

function releaseMilestone (id, itemId) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/release/' + id + '/' + itemId, requestOptions).then(handleResponse)
}

function deliver (id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/request/' + id, requestOptions).then(handleResponse)
}

function deliverMilestone (id, itemId) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/request/' + id + '/' + itemId, requestOptions).then(handleResponse)
}

function dispute (id, params) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(params)
  }

  return fetch(apiRoute() + '/api/v3/transaction/dispute/' + id, requestOptions).then(handleResponse)
}

function negotiate (id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/negotiate/' + id, requestOptions).then(handleResponse)
}

function quickCreate (transaction) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(transaction)
  }

  return fetch(apiRoute() + '/api/v3/transaction/quick', requestOptions).then(handleResponse)
}

function create (transaction) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(transaction)
  }

  return fetch(apiRoute() + '/api/v3/transaction', requestOptions).then(handleResponse)
}

function createFromRecurring (transaction) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(transaction)
  }

  return fetch(apiRoute() + '/api/v3/transaction/recurring/' + transaction.id, requestOptions).then(handleResponse)
}

function edit (transaction) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(transaction)
  }

  return fetch(apiRoute() + '/api/v3/transaction/edit/' + transaction._id, requestOptions).then(handleResponse)
}

function getEdit (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/edit/' + id, requestOptions).then(handleResponse)
}

function startEdit (id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: null
  }

  return fetch(apiRoute() + '/api/v3/transaction/edit/start/' + id, requestOptions).then(handleResponse)
}

function stopEdit (id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: null
  }

  return fetch(apiRoute() + '/api/v3/transaction/edit/stop/' + id, requestOptions).then(handleResponse)
}

function payEft (id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: null
  }

  return fetch(apiRoute() + '/api/v2/payment/eft/' + id, requestOptions).then(handleResponse)
}

function payWallet (id, saveDetails) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      saveAsPreferred: saveDetails
    })
  }

  return fetch(apiRoute() + '/api/v2/payment/wallet/' + id, requestOptions).then(handleResponse)
}

function payNewCard (id, card) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ card: null, card_details: card })
  }

  return fetch(apiRoute() + '/api/v2/payment/card/' + id, requestOptions).then(handleResponse)
}

function payCard (id, card, saveDetails) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      card: {
        id: card,
        saveAsPreferred: saveDetails
      },
      card_details: null
    })
  }

  return fetch(apiRoute() + '/api/v2/payment/card/' + id, requestOptions).then(handleResponse)
}

function poll3dSecure (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/payment/3dsecure/' + id, requestOptions).then(handleResponse)
}

function pollStitch (hash) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/payment/stitch/' + hash, requestOptions).then(handleResponse)
}

function payStitch (id, hash, forcenew) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      hash: hash,
      forcenew: forcenew
    })
  }

  return fetch(apiRoute() + '/api/v3/payment/stitch/' + id, requestOptions).then(handleResponse)
}

function getOzowUrl (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/payment/ozow/' + id, requestOptions).then(handleResponse)
}

function payOzow (id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: null
  }

  return fetch(apiRoute() + '/api/v2/payment/ozow/' + id, requestOptions).then(handleResponse)
}

function pollOzow (id, hash) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/payment/ozow/response/' + id + '/' + hash, requestOptions).then(handleResponse)
}

function share (id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/transaction/share/' + id, requestOptions).then(handleResponse)
}
