import { walletService } from '../services'
import Vue from 'vue'

const state = {
  ZAR: { statement: null, status: null },
  EUR: { statement: null, status: null },
  GBP: { statement: null, status: null },
  USD: { statement: null, status: null }
}

const actions = {
  get ({ dispatch, commit }, params) {
    commit('loading', params.currency)

    walletService.getStatement(params.currency, params.rows)
      .then(
        statement => {
          if (statement && statement.length > 0) {
            commit('loaded', { currency: params.currency, statement: statement })
          } else {
            commit('notFound', params.currency)
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('failed', params.currency)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getDated ({ dispatch, commit }, params) {
    commit('loading', params.currency)

    if ((parseInt((params.dateTo - params.dateFrom) / (1000 * 60 * 60 * 24), 10)) > 1) {
      const dateArray = []
      const currentDate = new Date(params.dateFrom)

      while (currentDate <= params.dateTo) {
        dateArray.push(new Date(currentDate))
        currentDate.setUTCDate(currentDate.getUTCDate() + 1)
      }

      const hold = []

      for (let i = dateArray.length; i > 0; i--) {
        const dateTo = dateArray[i]
        if (dateTo) {
          var l = dateArray[i - 1]
          const dateFrom = l ? dateArray[i - 1] : dateArray[i]

          walletService.getStatementDated(params.currency, dateFrom, dateTo)
            .then(
              statement => {
                if (statement && statement.length > 0) {
                  // console.log(statement)
                  for (let i = 0; i < statement.length; i++) {
                    hold.push(statement[i])
                  }
                  commit('loaded', { currency: params.currency, statement: hold })
                }
                dispatch('alert/clear', null, { root: true })
              },
              error => {
                commit('failed', params.currency)
                dispatch('alert/error', error, { root: true })
              }
            )
        }
      }
    } else {
      walletService.getStatementDated(params.currency, params.dateFrom, params.dateTo)
        .then(
          statement => {
            if (statement && statement.length > 0) {
              commit('loaded', { currency: params.currency, statement: statement })
            } else {
              commit('notFound', params.currency)
            }

            dispatch('alert/clear', null, { root: true })
          },
          error => {
            commit('failed', params.currency)
            dispatch('alert/error', error, { root: true })
          }
        )
    }
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state, currency) {
    Vue.set(state[currency], 'status', 'loading')
    Vue.set(state[currency], 'statement', null)
  },
  loaded (state, data) {
    Vue.set(state[data.currency], 'status', 'loaded')
    Vue.set(state[data.currency], 'statement', data.statement)
  },
  notFound (state, currency) {
    Vue.set(state[currency], 'status', 'not-found')
    Vue.set(state[currency], 'statement', null)
  },
  failed (state, currency) {
    Vue.set(state[currency], 'status', 'failed')
    Vue.set(state[currency], 'statement', null)
  },
  clear (state) {
    state.ZAR = { statement: null, status: null }
    state.EUR = { statement: null, status: null }
    state.GBP = { statement: null, status: null }
    state.USD = { statement: null, status: null }
  }
}

export const statement = {
  namespaced: true,
  state,
  actions,
  mutations
}
