import { contentService } from '../services'

const state = {
  slug: null,
  inModal: false,
  payload: null,
  status: null
}

const actions = {
  get ({ commit, dispatch }, slug) {
    commit('loading', slug)

    if (slug) {
      contentService.getContent(slug)
        .then(
          content => {
            if (content && content.length > 0) {
              var _content = content[0]
              _content.title.rendered = _content.title.rendered.replace('&#038;', '&')
              commit('loaded', _content)
            } else {
              commit('notFound')
            }

            dispatch('alert/clear', null, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  getInModal ({ commit }, slug) {
    commit('loadingInModal', slug)
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state, slug) {
    state.slug = slug
    state.inModal = false
    state.payload = null
    state.status = 'loading'
  },
  loadingInModal (state, slug) {
    state.slug = slug
    state.inModal = true
    state.payload = null
    state.status = 'loading'
  },
  loaded (state, content) {
    state.inModal = false
    state.payload = content
    state.status = 'loaded'
  },
  loadedInModal (state, content) {
    state.inModal = true
    state.payload = content
    state.status = 'loaded'
  },
  notFound (state) {
    state.inModal = false
    state.payload = null
    state.status = 'not-found'
  },
  failed (state) {
    state.inModal = false
    state.payload = null
    state.status = 'failed'
  },
  clear (state) {
    state.slug = null
    state.inModal = false
    state.payload = null
    state.status = null
  }
}

export const content = {
  namespaced: true,
  state,
  actions,
  mutations
}
