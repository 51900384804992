import { authService } from '../services'

const state = {
  status: null,
  email: null
}

const actions = {
  sendOtp ({ commit, dispatch }, username) {
    commit('sending')

    if (username) {
      authService.sendOtp(username)
        .then(
          () => {
            commit('sent')
            dispatch('alert/clear', null, { root: true })
          },
          error => {
            if (error.status !== 'Mail sent instead.') {
              commit('failed')
              dispatch('alert/error', error, { root: true })
            } else {
              commit('instead', error.email)
            }
          }
        )
    } else {
      commit('notFound')
    }
  },
  verifyOtp ({ commit, dispatch }, params) {
    commit('sending')

    if (params.username || params.otp) {
      authService.verifyOtp(params.username, params.otp)
        .then(
          user => {
            console.log(user)
            commit('verified')
            dispatch('alert/clear', null, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  sending (state) {
    state.status = 'sending'
  },
  sent (state) {
    state.status = 'sent'
  },
  instead (state, email) {
    state.status = 'instead'
    state.email = email
  },
  verified (state) {
    state.status = 'verified'
  },
  notFound (state) {
    state.status = 'not-found'
  },
  failed (state) {
    state.status = 'failed'
  },
  clear (state) {
    state.status = null
  }
}

export const otp = {
  namespaced: true,
  state,
  actions,
  mutations
}
