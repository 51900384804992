import { apiRoute, authHeader, handleResponse } from '../helpers'

export const bankService = {
  get,
  getAll
}

function get (currency) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/content/banks/' + currency, requestOptions).then(handleResponse)
}

function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/content/banks', requestOptions).then(handleResponse)
}
