import { apiRoute, authHeader, handleResponse } from '../helpers'

export const oauthService = {
  authorize,
  deleteCode,
  get,
  getAll,
  getStatus
}

function authorize (code) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: null
  }

  return fetch(apiRoute() + '/oauth/' + code, requestOptions).then(handleResponse)
}

function deleteCode (id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/oauth/' + id, requestOptions).then(handleResponse)
}

function get (code) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/oauth/' + code, requestOptions).then(handleResponse)
}

function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/oauth', requestOptions).then(handleResponse)
}

function getStatus (code) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/oauth/status/' + code, requestOptions).then(handleResponse)
}
