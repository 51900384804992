import { authService, companyService } from '../services'
import { router, jwtHelper } from '../helpers'

const user = JSON.parse(localStorage.getItem('user'))
const state = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null }

const actions = {
  login ({ dispatch, commit }, user) {
    commit('loginRequest', user)

    authService.login(user)
      .then(
        user => {
          commit('loginSuccess', user)
          dispatch('company/clear', null, { root: true })
          dispatch('user/get', null, { root: true })
          if (user.user.mobile && user.user.email) {
            dispatch('alert/success', 'Successfully logged in.', { root: true })
          } else {
            router.push('/profile')
            if (!user.user.mobile) {
              dispatch('alert/warning', 'We don\'t have your mobile number! Please, add it in your profile.', { root: true })
            } else {
              dispatch('alert/warning', 'We don\'t have your email address! Please, add it in your profile.', { root: true })
            }
          }
        },
        error => {
          commit('loginFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  loginFromToken ({ dispatch, commit }, user) {
    commit('loginRequest', user)

    if (jwtHelper.isStoredValid()) {
      commit('loginSuccess', user)
      dispatch('company/clear', null, { root: true })
      dispatch('user/get', null, { root: true })
    } else {
      commit('loginFailure')
    }
  },
  setCompany ({ dispatch, commit }, id) {
    commit('loginRequest', id)

    companyService.setCompany(id)
      .then(
        user => {
          commit('loginSuccess', user)
          dispatch('user/get', null, { root: true })
          dispatch('company/get', null, { root: true })
          dispatch('alert/success', user.user.company_name + ' selected.', { root: true })
        },
        error => {
          commit('loginFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  setIndividual ({ dispatch, commit }) {
    companyService.setIndividual()
      .then(
        user => {
          commit('loginSuccess', user)
          dispatch('user/get', null, { root: true })
          dispatch('company/clear', null, { root: true })
          dispatch('alert/success', 'Individual profile selected.', { root: true })
        },
        error => {
          commit('loginFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  loginAfterRegister ({ dispatch, commit }, _user) {
    commit('loginRequest', user)

    authService.login(_user)
      .then(
        user => {
          commit('loginSuccess', user)
          dispatch('alert/success', 'Successfully created.', { root: true })
          dispatch('company/clear', null, { root: true })
          if (_user.company) {
            companyService.getCompanies()
              .then(
                companies => {
                  if (companies && companies.length > 0) {
                    const _company = companies.find(_company => _company.name === _user.company)
                    dispatch('setCompany', _company._id)
                    router.push('/welcome/business')
                  } else {
                    dispatch('alert/error', 'Could not switch to selected company, please logout and try again.', { root: true })
                    router.push('/welcome/individual')
                  }
                },
                error => {
                  dispatch('alert/error', error, { root: true })
                  router.push('/welcome/individual')
                }
              )
          } else {
            router.push('/welcome/individual')
          }
        },
        error => {
          commit('loginFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ commit }, params) {
    commit('update', params)
  },
  logout ({ commit }) {
    authService.logout()
    commit('logout')
  },
  logoutRedirect ({ commit, rootState }) {
    authService.logout()
    commit('logout')
    if (rootState.oauth.code) {
      router.push('/authorize?code' + rootState.oauth.code)
    } else {
      router.push('/login')
    }
  },
  register ({ dispatch, commit }, user) {
    commit('registerRequest', user)

    authService.register(user)
      .then(
        () => {
          commit('registerSuccess')
          dispatch('alert/clear', null, { root: true })
          dispatch('loginAfterRegister', {
            username: user.email,
            password: user.password,
            recaptcha: user.recaptcha
          })
        },
        error => {
          commit('registerFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  reset ({ dispatch, commit }, user) {
    commit('resetRequest', user)

    authService.reset(user)
      .then(
        user => {
          commit('resetSuccess', user)
          dispatch('alert/success', 'Password was successfully reset. Please, try to login again.', { root: true })
        },
        error => {
          commit('resetFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  loginRequest (state, user) {
    state.status = { loggingIn: true }
    state.user = user
  },
  loginSuccess (state, user) {
    state.status = { loggedIn: true }
    state.user = user
  },
  loginFailure (state) {
    state.status = {}
    state.user = null
  },
  update (state, params) {
    state.user.user.mobile = params.mobile
    state.user.user.email = params.email
  },
  logout (state) {
    state.status = {}
    state.user = null
  },
  registerRequest (state) {
    state.status = { registering: true }
  },
  registerSuccess (state) {
    state.status = {}
  },
  registerFailure (state) {
    state.status = {}
  },
  resetRequest (state) {
    state.status = { resetting: true }
  },
  resetSuccess (state) {
    state.status = { reset: true }
  },
  resetFailure (state) {
    state.status = {}
  }
}

export const auth = {
  namespaced: true,
  state,
  actions,
  mutations
}
