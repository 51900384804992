import { transactionService } from '../services'

const state = {
  links: null,
  status: null
}

const actions = {
  getLinked ({ dispatch, commit }) {
    commit('loading')
    dispatch('alert/clear', null, { root: true })

    transactionService.getLinked()
      .then(
        transactions => {
          if (transactions && transactions.length > 0) {
            commit('loadedLinked', transactions)
          } else {
            commit('notFound')
          }
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.transaction = null
    state.transactions = null
    state.status = 'loading'
  },
  loadedLinked (state, data) {
    state.links = data
    state.status = 'loaded'
  },
  notFound (state) {
    state.transaction = null
    state.transactions = null
    state.status = 'not-found'
  },
  failed (state) {
    state.transaction = null
    state.transactions = null
    state.status = 'failed'
  },
  clear (state) {
    state.transaction = null
    state.transactions = null
    state.status = null
  }
}

export const transactionLinks = {
  namespaced: true,
  state,
  actions,
  mutations
}
