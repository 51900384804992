import { oauthService } from './../services'
import Vue from 'vue'

const state = {
  code: null,
  authorization: null,
  callback: null,
  status: null
}

const actions = {
  get ({ commit, dispatch, state }) {
    commit('loading')

    if (state.code) {
      oauthService.get(state.code)
        .then(
          code => {
            if (code) {
              commit('loaded', code)
            } else {
              commit('notFound')
            }

            dispatch('alert/clear', null, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  getStatus ({ commit, dispatch, state }) {
    commit('loading')

    if (state.code) {
      oauthService.getStatus(state.code)
        .then(
          code => {
            if (code) {
              commit('loaded', code)
            } else {
              commit('notFound')
            }

            dispatch('alert/clear', null, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  authorize ({ commit, dispatch, state }) {
    commit('saving')

    if (state.code) {
      oauthService.authorize(state.code)
        .then(
          callback => {
            if (callback) {
              commit('saved', callback)
            } else {
              commit('notFound')
            }

            dispatch('alert/clear', null, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  updateState ({ commit }, params) {
    commit('updateState', params)
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.authorization = null
    state.status = 'loading'
  },
  loaded (state, authorization) {
    state.authorization = authorization
    state.status = 'loaded'
  },
  saving (state) {
    state.callback = null
    state.status = 'saving'
  },
  saved (state, callback) {
    state.callback = callback
    state.status = 'saved'
  },
  notFound (state) {
    state.authorization = null
    state.status = 'not-found'
  },
  failed (state) {
    state.authorization = null
    state.status = 'failed'
  },
  updateState (state, params) {
    Vue.set(state, params.state, params.value)
  },
  clear (state) {
    state.code = null
    state.authorization = null
    state.callback = null
    state.status = null
  }
}

export const oauth = {
  namespaced: true,
  state,
  actions,
  mutations
}
