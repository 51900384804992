const state = {
  types: {
    Created: {
      name: 'Created',
      description: {
        default: 'This transaction is now secured by Truzo.',
        buyer: 'This transaction is now secured by Truzo.',
        seller: 'This transaction is now secured by Truzo.',
        agent: 'This transaction is now secured by Truzo.'
      }
    },
    Completed: {
      name: 'Completed',
      description: {
        default: 'This transaction is now completed securely with Truzo.'
      },
      tobedone: {
        default: 'Waiting for the transaction to be completed.',
        action: 'Waiting for the transaction to be completed.'
      }
    },
    /* Accepted: {
      name: 'Accepted',
      description: 'This transaction has been accepted by transacting parties.'
    }, */
    Approved: {
      name: 'Transaction approved',
      description: {
        buyer: 'This transaction has been approved by the buyer.',
        seller: 'This transaction has been approved by the seller.',
        agent: 'This transaction has been approved by the agent.',
        default: 'This transaction has been approved by the transaction participant.'
      },
      tobedone: {
        default: 'Waiting for the transaction to be approved.',
        action: 'You need to approve this transaction to continue. If you\'re not satisfied with the details of the transaction, you can either edit it or request cancellation.'
      }
    },
    'Funding Received': {
      name: 'Funding received',
      description: {
        default: 'Funds for this transaction have been received and are now being verified.',
        buyer: 'Funds for this transaction have been received and are now being verified.',
        seller: 'Funds for this transaction have been received and are now being verified.',
        agent: 'Funds for this transaction have been received and are now being verified.'
      }
    },
    'Funding Failed': {
      name: 'Funding failed',
      description: {
        default: 'Funds for this transaction have not been received.'
      }
    },
    Funded: {
      name: 'Transaction funded',
      description: {
        default: 'This transaction has been funded.',
        buyer: 'This transaction has been funded.',
        seller: 'This transaction has been funded.',
        agent: 'This transaction has been funded.'
      },
      tobedone: {
        default: 'Waiting for the transaction to be funded.',
        action: 'You need to fund the transaction before the seller can proceed with the delivery of your goods.'
      }
    },
    Delivered: {
      name: 'Delivered',
      description: {
        default: 'This transaction has been delivered.',
        buyer: 'This transaction has been delivered.',
        seller: 'This transaction has been delivered.',
        agent: 'This transaction has been delivered.'
      },
      tobedone: {
        default: 'Waiting for {{type}} to be delivered.',
        action: 'You now need to deliver {{type}} as per transaction details.'
      }
    },
    Released: {
      name: 'Funds released',
      description: {
        default: 'Funds for this transaction has been released.',
        buyer: 'Funds for this transaction has been released.',
        seller: 'Funds for this transaction has been released.',
        agent: 'Funds for this transaction has been released.'
      },
      tobedone: {
        default: 'Waiting for the funds to be release.',
        action: 'You have {{days}} days from the date of delivery to inspect the goods upon delivery and release the funds. If you are unsatisfied you can raise a dispute to get your money back.'
      }
    },
    'Cancellation requested': {
      name: 'Cancellation requested',
      description: {
        default: 'Cancellation of this transaction has been requested.',
        buyer: 'Cancellation of this transaction has been requested.',
        seller: 'Cancellation of this transaction has been requested.',
        agent: 'Cancellation of this transaction has been requested.'
      }
    },
    'Cancellation approved': {
      name: 'Cancellation approved',
      description: {
        default: 'Cancellation of this transaction has been approved.',
        buyer: 'Cancellation of this transaction has been approved.',
        seller: 'Cancellation of this transaction has been approved.',
        agent: 'Cancellation of this transaction has been approved.'
      },
      tobedone: {
        default: 'Waiting for cancellation of this transaction to be approved.',
        action: 'If you\'re happy to cancel this transaction, please do so. Otherwise dispute the cancellation or contact Truzo support for further actions.'
      }
    },
    Cancelled: {
      name: 'Transaction cancelled',
      description: {
        default: 'This transaction has been cancelled.',
        buyer: 'This transaction has been cancelled.',
        seller: 'This transaction has been cancelled.',
        agent: 'This transaction has been cancelled.',
        admin: 'This transaction has been cancelled.'
      },
      tobedone: {
        default: 'Waiting for this transaction to be cancelled.'
      }
    },
    Disputed: {
      name: 'Dispute lodged',
      description: {
        default: 'This transaction has been disputed.',
        buyer: 'This transaction has been disputed.',
        seller: 'This transaction has been disputed.',
        agent: 'This transaction has been disputed.'
      }
    },
    Updated: {
      name: 'Transaction updated',
      description: {
        default: 'This transaction has been updated.',
        buyer: 'This transaction has been updated.',
        seller: 'This transaction has been updated.',
        agent: 'This transaction has been updated.'
      }
    },
    'Offline negotiation': {
      name: 'Offline Negotiation',
      description: {
        default: 'This transaction is moved to offline negotiation phase. Inspection days are paused for 5 days.',
        buyer: 'This transaction is moved to offline negotiation phase. Inspection days are paused for 5 days.',
        seller: 'This transaction is moved to offline negotiation phase. Inspection days are paused for 5 days.',
        agent: 'This transaction is moved to offline negotiation phase. Inspection days are paused for 5 days.'
      }
    },
    'Dispute removed': {
      name: 'Dispute removed',
      description: {
        default: 'Dispute for this transaction has been removed.',
        admin: 'Dispute for this transaction has been removed.'
      }
    }
  }
}

export const transactionAudit = {
  namespaced: true,
  state
}
