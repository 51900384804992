const state = {
  transaction: null,
  new: false
}

const actions = {
  setTransactions ({ commit }, transaction) {
    commit('setTransactions', transaction)
  },
  clear ({ commit }, property) {
    commit('clear', property)
  }
}

const mutations = {
  setTransactions (state, transaction) {
    state.transaction = transaction
  },
  clear (state, property) {
    if (property === 'all' || property === 'job') {
      state.transaction = null
    }
  }
}

export const transactionSelected = {
  namespaced: true,
  state,
  actions,
  mutations
}
