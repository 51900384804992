import { apiRoute, authHeader, handleResponse } from '../helpers'

export const companyService = {
  attach,
  addUserCompany,
  addNewUserCompany,
  deleteCompanyUser,
  deleteAccount,
  getAccounts,
  getCompanies,
  saveAccount,
  saveCompanyUser,
  saveCompanyUserDesignation,
  saveCompanyUserShare,
  setCompany,
  setIndividual,
  updateAccount
}

function addUserCompany (id, user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return fetch(apiRoute() + '/api/v3/users/company/user/' + id, requestOptions).then(handleResponse)
}

function addNewUserCompany (user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return fetch(apiRoute() + '/api/v3/users/company/user', requestOptions).then(handleResponse)
}

function attach (files) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(files)
  }

  return fetch(apiRoute() + '/api/v3/users/company/attach', requestOptions).then(handleResponse)
}

function deleteCompanyUser (id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/company/user/' + id, requestOptions).then(handleResponse)
}

function deleteAccount (id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/company/account/' + id, requestOptions).then(handleResponse)
}

function getAccounts () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/company/account', requestOptions).then(handleResponse)
}

function getCompanies () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/companies', requestOptions).then(handleResponse)
}

function saveAccount (account) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(account)
  }

  return fetch(apiRoute() + '/api/v3/users/company/account', requestOptions).then(handleResponse)
}

function saveCompanyUser (id, user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return fetch(apiRoute() + '/api/v3/users/company/user/' + id, requestOptions).then(handleResponse)
}

function saveCompanyUserDesignation (id, user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return fetch(apiRoute() + '/api/v3/users/company/user/designation/' + id, requestOptions).then(handleResponse)
}

function saveCompanyUserShare (id, user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return fetch(apiRoute() + '/api/v3/users/company/user/share/' + id, requestOptions).then(handleResponse)
}

function setIndividual () {
  return new Promise(function (resolve, reject) {
    const stored = localStorage.getItem('individual')
    if (stored) {
      localStorage.setItem('user', stored)
      resolve(JSON.parse(stored))
    } else {
      reject(new Error('No individual token stored.'))
    }
  })
}

function setCompany (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: null
  }

  return fetch(apiRoute() + '/api/v3/users/company/' + id, requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user.token) {
        localStorage.setItem('user', JSON.stringify(user))
      }

      return user
    })
}

function updateAccount (params) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(params.account)
  }

  return fetch(apiRoute() + '/api/v3/users/company/account/' + params.id, requestOptions).then(handleResponse)
}
