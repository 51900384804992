import Vue from 'vue'

const state = {
  walkthough: {
    type: 'Individual',
    complete: false
  },
  registration_type: 'Individual',
  invited: false,
  invitation_code: null,
  name: null,
  surname: null,
  company: '',
  company_reg: '',
  company_country: null,
  mobile: null,
  phonex: null,
  email: null,
  comms_method: 'Only Email',
  password: null,
  business: {
    status: null,
    type: null,
    shareholders: null,
    nationality: null,
    directors: null,
    country: '',
    name: null,
    reg_number: null,
    email: null,
    relationship: []
  },
  payment: {
    card: {
      number: null,
      expMonth: null,
      expYear: null,
      expiration: null,
      cvc: null
    },
    name: null,
    save_details: true,
    processing: false,
    processed: null,
    error: null,
    secure: null,
    orderid: null,
    have_coupon: true,
    coupon: 'FREEREGISTRATION'
  },
  verification: {
    stage: 'mobile',
    mobile: {
      sending: false,
      sent: false,
      failed: false,
      verified: false,
      skipped: false,
      attempt: 0
    },
    email: {
      sending: false,
      sent: false,
      failed: false,
      verified: false,
      skipped: false,
      otp: null,
      attempt: 0
    }
  }
}

const actions = {
  updateState ({ commit }, params) {
    commit('updateState', params)
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  updateState (state, params) {
    Vue.set(state, params.state, params.value)
  },
  clear (state) {
    state.type = null
    state.role = null
    state.transaction = {}
  }
}

export const registration = {
  namespaced: true,
  state,
  actions,
  mutations
}
