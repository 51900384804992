import { userService } from '../services'

const state = {
  audit: null,
  audits: null,
  status: null
}

const actions = {
  get ({ dispatch, commit }) {
    commit('loading')
    dispatch('alert/clear', null, { root: true })

    userService.audit()
      .then(
        audit => {
          if (audit) {
            commit('loaded', audit)
          } else {
            commit('notFound')
          }
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  loadIn ({ commit }, audit) {
    commit('loadIn', audit)
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.audit = null
    state.audits = null
    state.status = 'loading'
  },
  loaded (state, data) {
    if (data && Array.isArray(data)) {
      state.audit = null
      state.audits = data
    } else {
      state.audit = data
      state.audits = null
    }
    state.status = 'loaded'
  },
  loadIn (state, audit) {
    state.audit = audit
    state.status = 'loaded'
  },
  notFound (state) {
    state.audit = null
    state.audits = null
    state.status = 'not-found'
  },
  failed (state) {
    state.audit = null
    state.audits = null
    state.status = 'failed'
  },
  clear (state) {
    state.audit = null
    state.audits = null
    state.status = null
  }
}

export const audit = {
  namespaced: true,
  state,
  actions,
  mutations
}
