import { feesService } from '../services'

const state = {
  fees: null,
  status: null
}

const actions = {
  get ({ dispatch, commit, rootState }, amount) {
    commit('loading')

    if (amount) {
      feesService.getFees(amount, rootState.currency.name)
        .then(
          fees => {
            if (fees) {
              commit('loaded', fees)
            } else {
              commit('notFound')
            }

            dispatch('alert/clear', null, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.fees = null
    state.status = 'loading'
  },
  loaded (state, fees) {
    state.fees = fees
    state.status = 'loaded'
  },
  notFound (state) {
    state.fees = null
    state.status = 'not-found'
  },
  failed (state) {
    state.fees = null
    state.status = 'failed'
  },
  clear (state) {
    state.fees = null
    state.status = null
  }
}

export const fees = {
  namespaced: true,
  state,
  actions,
  mutations
}
