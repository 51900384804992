import { transactionService } from '../services'

const state = {
  transaction: null,
  transactions: null,
  links: null,
  status: null
}

const actions = {
  get ({ dispatch, commit }, id) {
    commit('loading')
    dispatch('alert/clear', null, { root: true })

    transactionService.get(id)
      .then(
        transaction => {
          if (transaction) {
            commit('loaded', transaction)
          } else {
            commit('notFound')
          }
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getAll ({ dispatch, commit }) {
    commit('loading')
    dispatch('alert/clear', null, { root: true })

    transactionService.getAll()
      .then(
        transactions => {
          if (transactions && transactions.length > 0) {
            commit('loaded', transactions)
          } else {
            commit('notFound')
          }
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getLinked ({ dispatch, commit }) {
    commit('loading')
    dispatch('alert/clear', null, { root: true })

    transactionService.getLinked()
      .then(
        transactions => {
          if (transactions && transactions.length > 0) {
            commit('loadedLinked', transactions)
          } else {
            commit('notFound')
          }
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  loadIn ({ commit }, transaction) {
    commit('loadIn', transaction)
  },
  quickCreate ({ dispatch, commit }, transaction) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (transaction) {
      transactionService.quickCreate(transaction)
        .then(
          transaction => {
            commit('saved', transaction)
            dispatch('fees/clear', null, { root: true })
            dispatch('transactionNew/clear', null, { root: true })
            dispatch('alert/success', 'New transaction has been created successfully. Reference: ' + transaction.reference, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  create ({ dispatch, commit }, transaction) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (transaction) {
      transactionService.create(transaction)
        .then(
          transaction => {
            commit('saved', transaction)
            dispatch('fees/clear', null, { root: true })
            dispatch('transactionNew/clear', null, { root: true })
            dispatch('alert/success', 'New transaction has been created successfully. Reference: ' + transaction.reference, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  edit ({ dispatch, commit }, transaction) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (transaction) {
      transactionService.edit(transaction)
        .then(
          transaction => {
            commit('saved', transaction)
            dispatch('fees/clear', null, { root: true })
            dispatch('transactionNew/clear', null, { root: true })
            dispatch('alert/success', 'Transaction has been updated successfully. Reference: ' + transaction.reference, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.transaction = null
    state.transactions = null
    state.status = 'loading'
  },
  loaded (state, data) {
    if (data && Array.isArray(data)) {
      state.transaction = null
      state.transactions = data
    } else {
      state.transaction = data
      state.transactions = null
    }
    state.status = 'loaded'
  },
  loadedLinked (state, data) {
    state.links = data
    state.status = 'loaded'
  },
  loadIn (state, transaction) {
    state.transaction = transaction
    state.status = 'loaded'
  },
  saving (state) {
    state.transaction = null
    state.transactions = null
    state.status = 'saving'
  },
  saved (state, transaction) {
    state.transaction = transaction
    state.transactions = null
    state.status = 'saved'
  },
  notFound (state) {
    state.transaction = null
    state.transactions = null
    state.status = 'not-found'
  },
  failed (state) {
    state.transaction = null
    state.transactions = null
    state.status = 'failed'
  },
  clear (state) {
    state.transaction = null
    state.transactions = null
    state.status = null
  }
}

export const transaction = {
  namespaced: true,
  state,
  actions,
  mutations
}
