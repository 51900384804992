import { couponService } from '../services'

const state = {
  fees: null,
  status: null
}

const actions = {
  get ({ dispatch, commit, rootState }, params) {
    commit('loading')

    if (params.coupon && params.fees) {
      var promises = []
      params.fees.forEach(record => {
        promises.push(couponService.getCoupon(params.coupon, params.amount, record.method, rootState.currency.name))
      })

      Promise.all(promises)
        .then(
          fees => {
            if (fees && fees.length === params.fees.length) {
              const _fees = []
              params.fees.forEach(record => {
                const _newFee = fees.find(fee => parseFloat(Math.round(fee.original * 100) / 100) === parseFloat(record.fee))
                if (_newFee) {
                  _fees.push({
                    method: record.method,
                    fee: _newFee.discounted
                  })
                }
              })

              dispatch('alert/success', 'Coupon applied!', { root: true })
              commit('loaded', _fees)
            } else {
              dispatch('alert/warning', 'Coupon could not be found!', { root: true })
              commit('notFound')
            }
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
        .catch(
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.fees = null
    state.status = 'loading'
  },
  loaded (state, fees) {
    state.fees = fees
    state.status = 'loaded'
  },
  notFound (state) {
    state.fees = null
    state.status = 'not-found'
  },
  failed (state) {
    state.fees = null
    state.status = 'failed'
  },
  clear (state) {
    state.fees = null
    state.status = null
  }
}

export const coupon = {
  namespaced: true,
  state,
  actions,
  mutations
}
