import { oauthService } from '../services'

const state = {
  auths: null,
  status: null
}

const actions = {
  get ({ dispatch, commit }) {
    commit('loading')

    oauthService.getAll()
      .then(
        auths => {
          if (auths) {
            commit('loaded', auths)
          } else {
            commit('notFound')
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  delete ({ dispatch, commit }, code) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (code) {
      oauthService.deleteCode(code)
        .then(
          () => {
            commit('saved')
            dispatch('alert/success', 'Authorization successfully deleted.', { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.auths = null
    state.status = 'loading'
  },
  loaded (state, auths) {
    state.auths = auths
    state.status = 'loaded'
  },
  saving (state) {
    state.auths = null
    state.status = 'saving'
  },
  saved (state) {
    state.auths = null
    state.status = 'saved'
  },
  notFound (state) {
    state.auths = null
    state.status = 'not-found'
  },
  failed (state) {
    state.auths = null
    state.status = 'failed'
  },
  clear (state) {
    state.auths = null
    state.status = null
  }
}

export const userOauth = {
  namespaced: true,
  state,
  actions,
  mutations
}
