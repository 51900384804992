const state = {
  type: null,
  embedded: false,
  link: null,
  id: null
}

const actions = {
  save ({ commit }, params) {
    commit('save', params)
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  save (state, params) {
    state.type = params.type
    state.embedded = params.embedded
    state.link = params.link
    state.id = params.id
  },
  clear (state) {
    state.type = null
    state.embedded = null
    state.link = null
    state.id = null
  }
}

export const link = {
  namespaced: true,
  state,
  actions,
  mutations
}
